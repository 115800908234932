@import "variables";
@import "~bootstrap/scss/bootstrap.scss";
@import "~vue-next-select/dist/index.min.css";

.close {
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: 0.25em 0.25em;
    color: #000;
    background: transparent $btn-close-bg;
    border: 0;
    border-radius: 0.25rem;
    opacity: .5;
}

.big {
    font-size: 1.6em;
    color: #666;
}
.mid {
    font-size: 1.2em;
    color: #444;
}

.deleted {
    td,a {
        color: #ccc !important;
    }
}

.btn-th {
    width: 60px;
    text-align: center;
}
.vue-select {
    .vue-input {
        input {
            background: transparent;
            &::placeholder {
                color: #444;
            }
        }
    }
}
.sticky {
    position: sticky;
    z-index: 99;
    top: 5rem;
    right: 5rem;
    background-color: rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.5);
    .pagination {
        margin: 0;
        opacity: .9;
        z-index: 99;
    }
}

.pointer {
    cursor: pointer;
}
.draggable {
    cursor: grab;
}

.nowrap {
    white-space: nowrap;
}